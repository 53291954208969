import React, { useEffect } from 'react'
import FranchiseImg from '../images/HeroImg3.png'

const Franchise = () => {
  useEffect(() => {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);

      if (section) {
        console.log("Found section:", section);
        section.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("Section not found with ID:", sectionId);
      }
    };
    const sectionId = 'franchise';
    scrollToSection(sectionId);
  }, []);
  return (
    <section className="book_section layout_padding" id='franchise'>
        <div className="container">
          <div className="heading_container">
            <h2>Request for Franchise</h2>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="form_container" style={{marginTop:'30px'}}>
                <form action="">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                    />
                  </div>
                  <div>
                    <select className="form-control nice-select wide">
                      <option value="" disabled selected>
                        How many persons?
                      </option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  <div>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="btn_box">
                    <button>Submit</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-7">
              <div><img src={FranchiseImg} style={{marginTop:'-100px'}}></img></div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Franchise
