import { Container, Heading,Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import WhyChooseUsImg from '../images/WhyChooseUsImg.png'
const About = () => {


  useEffect(() => {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);

      if (section) {
        console.log("Found section:", section);
        section.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("Section not found with ID:", sectionId);
      }
    };

    const sectionId = 'about';
    scrollToSection(sectionId);
  }, []);


  return (
    <section className="about_section layout_padding bg-white" id='about'>
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="img-box">
            <img src={WhyChooseUsImg} alt="" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="detail-box">
            <div className="heading_container">
              <h2 style={{color:'black'}}>Why Choose Us</h2>
            </div>
            <p style={{color:'black'}}>
              The Robot Restaurants are the leaders in using advanced
              robotics to create dishes that challenge and delight the
              senses, while ensuring a smooth and enjoyable service. You can
              also customize your menu and enjoy interactive shows, making
              your dining adventure truly unique and adaptable. Whether it’s
              a birthday, an anniversary, or a special occasion, The Robot
              Restaurants will make it remarkable with their robotic
              gastronomy services.
            </p>
            {/* <a href="">Read More</a> */}
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default About
