import About from './Components/About';
import Footer from './Components/Footer';
import Franchise from './Components/Franchise';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/About' element={<About/>} />
        <Route path='/Franchise' element={<Franchise/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
